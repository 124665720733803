.Report_master {
    height: auto;
    position: relative;
    width: 50rem;
    /* border: 1px solid var(--ProjectColor); */
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    gap: 14px;
    justify-content: flex-start;
    flex-direction: column;
}

/* .Report_master_preview{
    display: flex;
} */

/* ReportComponent.css */

.completed_report_1111 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    row-gap: 5px;
   
}

/* .hesyyd_p0{
    min-height: 600px;
} */
.completed_report {
    font-family: Arial, sans-serif;
   
    /* border: 1px solid #ccc; */
   
    padding: 10px;
   
    /* width: 100%; */
    width: 90%;
    display: flex;
    justify-content: center;
}
/* .culture_report_data{
    width: 90%;
} */
.completed_report p {
    display: flex;
    justify-content: center;
    margin: 5px 5px;
    gap: 200px;
    font-size: 13px;

}

.completed_report h4 strong {
    width: 100%;
    display: flex;
    justify-content: center !important;
}

.completed_report strong {
    /* width: 30px; */
    margin-right: 3px;

}

.completed_report img {
 
    width: 200px;
    position: relative;


    object-fit: contain;
    height: 120px;
}

.completed_report h2,.completed_report h3 {
    display: flex;
    justify-content: center;
}

.completed_report h2 strong {
    display: flex;
    width: 100% !important;
    justify-content: center;
}

.completed_report h4 {
    font-size: 20px;
    /* Example font size for heading */
    margin-bottom: 10px;
    /* Example margin for spacing */
}



/* Microbiology Ot report style */


.gghbuy_o9 table{
    width: 90% !important;
}

.ck-content .table table td{
text-align: start;
}
.completed_report04948 strong{
    width: 105px !important;
    margin-right: 3px;
}

.completed_report04948 ul{
    margin-left: 40px;
}
.completed_report04948 i {
    display: contents  !important;
    font-weight: 600;
}
.completed_report04948 p{
    display: flex;
    justify-content: start;
    margin: 5px 5px;
    gap: 0px  !important;
    font-size: 13px;

}
.completed_report04948 ol {
    list-style-type: none; /* Removes the order numbers */
}

.completed_report04948 ol li strong {
    text-decoration: underline;
    margin-left: -14px !important;
    padding-bottom: 5px;
}
.completed_report04948 ol li {
    margin-left: 30px;
    margin-top: 5px;
}


.data-row {
    margin-bottom: 10px;
    /* Example margin between rows */
}

.data-row strong {
    display: inline-block;
    /* Ensure strong tags (titles) are displayed inline */
    width: 120px;
    /* Example width for left alignment */
    font-weight: bold;
    /* Example font-weight for titles */
}

.data-row p {
    display: inline-block;
    /* Ensure p tags (data) are displayed inline */
    margin-left: 10px;
    /* Example margin for spacing between title and data */
}



.report_preview {
    width: 40rem;

}

.report_table {
    border-collapse: collapse;
    width: 100%;
}

.customMultiSelect {
    width: 160px;
    background-color: aqua !important;
}

.dropdown-container {
    position: absolute;
    top: 0px;
    right: 500px;
    left: 0;
    width: 161px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.dropdown-content {

    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    gap: 20px !important;

}

.histopathologycode {
    position: relative;
    display: flex;
    bottom: -10px;
    align-items: center;
    justify-content: center;
}

.Report_master1 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.report_table thead {
    height: 15px;
    border: 2px 0px solid var(--ProjectColor) !important;
    background-color: rgb(216, 222, 228);
    /* width: 100%; */
    /* overflow-x: auto; */

}

.culturetest_div_head {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* row-gap: 4px;
    margin-top: 10px; */

}



.culturetest_div {
    display: flex;
    width: 100%;
    gap: 10px;
    justify-content: space-around;
    align-items: flex-start;
    font-size: 14px;
    margin-top: 10px;
}

.culturetest_div label {
    display: flex;
    font-weight: bold;
    justify-content: space-between;
    align-items: center;
    width: 300px;
}

.culturetest_div p {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

}

.culture_test_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 15px;
    /* border-bottom: 1px solid black; */
    margin-top: 5px;
}

.culture_test_header p {
    padding-bottom: 5px;
    font-weight: bold;
}

.summaprintsummauuu {
    display: flex;
    width: 100%;
    justify-items: flex-start;
    flex-wrap: wrap; 
    padding-left: 10px;
}


.Report_records {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.report_head ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
}

.list_report {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    list-style: none;
    font-size: 10px;
    font-weight: 300;
}

.report_table {
    width: 100%;


}





.report_table td,
.report_table th {
    font-size: 15px !important;
    padding: 6px;
    text-align: start;
    /* border-bottom: 1px solid #ddd; */
}


.report_table td {
    font-size: 15px;
    padding: 6px;

    /* text-align: start; */
    /* border-bottom: 1px solid #ddd; */
    margin-left: 20px;
}


.uyhfgr_gf {
    text-align: start;
  
}

.yufytf_0 th{
    width: 100px;
}



.Register_btn_con_barcode {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;


}


.Inpterpretation_head p {
    display: flex;
    width: 100%;
    /* justify-content: ; */
    font-size: 15px;
    font-weight: bolder;
    /* border-bottom: 2px solid black; */
    align-items: center;
    color: rgba(0, 0, 0, 74%);
}

.Interpretation_Detail {
    width: auto;
    font-size: 15px;

}

.report_qr {
    display: flex;
    /* align-items: center; */
    width: 100%;
    /* justify-content: flex-end; */
}

.Register_btn_con_QRcode {
    margin-right: 20px;
    padding-top: 10px;
    box-sizing: border-box;
}

.Register_btn_con_Doc_sign {
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    width: 100%;
    /* justify-content: flex-start; */
}

.Register_btn_con_Doc_sign p {
    width: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
}

.docsign {
    width: 200px;
    height: 45px;
    position: relative;
    left: 65px;
    padding-bottom: 10px;
    /* background-color: #ddd; */
}

.bottomcode {

    align-items: flex-end;
    height: 150px;

}

.interpretation_container {
    margin-top: 10px;
}

.textareainterpretation {
    width: 814px;
    height: 121px;
    margin-top: 10px;
}

/* Barcode container */

.barcode_container_print_data {
    width: 235px;
    height: 150px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
    border: 1px solid;
    border-color: black;
    border-radius: 10px;
    box-shadow: 1px 0px 10px 0.5px;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
    box-sizing: border-box;
}

.parent_barcode_div {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;
    gap: 10px;
    box-sizing: border-box;
}

.image_in_report {
    width: 160px;
    height: 100px;
    padding: 20px;
}

.barcode_image_content{
     position: relative;
     bottom: 5px;
}

.barcode_patient {
    display: flex;
    /* width: 100%; */
    /* position: relative; */
    /* top: 20px; */
    gap: 5px;
    /* margin-top: 5px; */
}

.barcode_dept {
    display: flex;
    /* margin-bottom: 5px; */
    gap: 2px;
    /* position: relative; */
    /* top: 20px; */
}



.barcodeprintbtn {
    width: 100px;
    height: 20px;
    border-radius: 5px;
    outline: none;
}

.barcodeprintbtn:hover {
    cursor: pointer;
    background-color: var(--ProjectColor);
}

.pdf-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.background_text_for_reprint {
    position: absolute;
    top: 0;
    left: 200;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    /* Ensure this doesn't block interactions with the iframe */
}

.text_forprint {
    font-size: 48px;
    color: rgba(255, 0, 0, 0.5);
    /* Red color with reduced opacity */
    transform: rotate(-45deg);
    white-space: nowrap;
}

.summa_service input[type="radio"] {
    transform: scale(1.2);
    margin: 0px;
    margin-right: 5px;
}

.santhu_billing_review {
    border-radius: 15px;
}

.santhu_invoice_head {
    align-items: center;
    justify-content: center;
}

.service_ratings {
    display: flex;

    font-size: 13px;
    font-weight: 600;
    gap: 15px;
    justify-content: center;
    align-items: center;
}

.service_ratings label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100px;
}

.doctor_details_for_review {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    font-size: 17px;
    font-weight: 800;
    color: var(--labelcolor);
}

.hewydtyytw {
    width: 200px;
}

.summa_service {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
}

.summa_service label {
    display: flex;
    gap: 3px;
    justify-content: flex-start;
    align-items: center;
    width: 130px;
}

.tydfrt76 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}



.comments_for_interpretation {
    padding: 0px 5px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: grey;
    gap: 5px;
    position: relative;
    top: 10px;
    margin-bottom: 25px;
}

.comments_for_interpretation label {
    width: 120px;
    display: flex;
    font-size: var(--fontsize);
    font-weight: bold;
    justify-content: space-between;
    text-align: start;
    align-items: center;
    gap: 10px;
    color: var(--labelcolor);
}

.comments_for_interpretation textarea {
    width: 90%;
    height: 60px;
}

.containernamesvg {
    font-size: 20px;
}

.dwedwd{
    display: none !important;
}
.ewfergrdgd {
    width: 100px !important;
}

.ewdwedcsdwe {
    width: 100% !important;
    margin-top: 5px !important;
}




.new_billing_div_report {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    width: 80%;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    border: none;
    padding: 2px;
    height: 19px  !important;
}

.hhh_p2q{
    width: 100% !important;
}




.tffyfcgyt_o label{
    width: 100px !important;
}

.new_billing_div_report label {
    width: 170px;
    display: flex;
    font-size: 15px !important;
    justify-content: space-between;
    text-align: start;
    align-items: center;
}

.new_billing_div_report span {
    width: 280px;
    display: flex;
    justify-content: flex-start;
    text-align: start;
    font-weight: bold;
}

.wiioo900 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 65px;
    text-align: center;
    margin-top: 5px;


}

.dsdwd_p9o{
    flex-direction: column;
    gap: 0px !important;

}

.nbjui_94d{
    width: 50% !important;
}

.print_body {
    width: 100% !important;
}



.print-table33 {
    width: 100% !important;
    height: 100vh;
    position: relative;
    
}

.report_table_for_micro {
    width: 100% !important;
    margin-bottom: 7px;

}

.report_table_for_micro td {
    padding: 2px;
    padding-top: 15px !important;
    padding-left: 7px !important;

vertical-align: top;
    width: 0px;
}



.table-container554 {
    width: 100% !important;
    display: flex;
    gap: 20px;
    justify-content: center;
}


.loader {
    position: relative;
    width: 2.5em;
    height: 2.5em;
    transform: rotate(165deg);
}

.loader:before,
.loader:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 0.5em;
    height: 0.5em;
    border-radius: 0.25em;
    transform: translate(-50%, -50%);
}

.loader:before {
    animation: before8 2s infinite;
}

.loader:after {
    animation: after6 2s infinite;
}




@keyframes before8 {
    0% {
        width: 0.5em;
        box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75), -1em 0.5em rgba(111, 202, 220, 0.75);
    }

    35% {
        width: 2.5em;
        box-shadow: 0 -0.5em rgba(225, 20, 98, 0.75), 0 0.5em rgba(111, 202, 220, 0.75);
    }

    70% {
        width: 0.5em;
        box-shadow: -1em -0.5em rgba(225, 20, 98, 0.75), 1em 0.5em rgba(111, 202, 220, 0.75);
    }

    100% {
        box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75), -1em 0.5em rgba(111, 202, 220, 0.75);
    }
}

@keyframes after6 {
    0% {
        height: 0.5em;
        box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75), -0.5em -1em rgba(233, 169, 32, 0.75);
    }

    35% {
        height: 2.5em;
        box-shadow: 0.5em 0 rgba(61, 184, 143, 0.75), -0.5em 0 rgba(233, 169, 32, 0.75);
    }

    70% {
        height: 0.5em;
        box-shadow: 0.5em -1em rgba(61, 184, 143, 0.75), -0.5em 1em rgba(233, 169, 32, 0.75);
    }

    100% {
        box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75), -0.5em -1em rgba(233, 169, 32, 0.75);
    }
}

.loader {
    position: absolute;
    top: calc(50% - 1.25em);
    left: calc(50% - 1.25em);
}

.new_his_br_ffit{
    display: flex;
    justify-content: space-around;
    width: 42%;
}


@media print {

    @page {
        size: A4;
        margin: 0;
        padding: 10px;
        /* margin-top: 40px !important; */
        margin-bottom: 40px !important;
    }


   
    .Rhugt_report {
        display: none;
    }

    .dwedwd{
        display: none !important;
    }
    /* .culture_report_data{
        width: 90%;
    } */
}


.flex_head_content {
    display: flex;
    flex-direction: column;
    row-gap: 30px !important;
}

.new_billing_address_1_fix {

    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    font-size: 10px;
    align-items: center;

}

.new_billing_address {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 5px;
}

.ewdjhdu73 {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    justify-content: flex-start;
}

.new_billing_address_2 {
    display: flex;
    flex-direction: column;
    gap: 0px;
    justify-content: flex-start;
    font-size: 12px;
    align-items: center;

}


.New_billlling_invoice_head_fix {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: column;
}

.New_billlling_invoice_head_fix_space {
    display: flex;
    justify-content: space-around;
    align-items: center;
}


.new_billing_logo_con_fix img {
    width: 100%;
    height: 50px;
    object-fit: contain;
}

.new_billing_div label {
    font-size: 11px !important;
}

.new_billing_div {
    font-size: 11px !important;
}

.footer_fix_content {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.footer_print_fix_i8 {
    width: 100%;
}

.footer_fix_conten_fyft {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}


.microejeo {
    display: flex;
    justify-content: space-around;
/* gap: 200px; */
    width: 80%;

}



.footer_fix_conten_fyft td {
    padding: 2px !important;
}

.doctr_sign_fix7 {
    display: flex;
    position: relative;
    left: 600px;
    width: 200px;
    height: 90px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.doctr_sign_fix7 p {
    font-size: 11px;
}

.docsign_fix {
    width: 95px;
    height:70px;
    object-fit: contain;
}

.print-page3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */

}

.head_Container_fix {
    padding: 10px !important;
}

.wedscr54_yrt665 {
    width: 80px;
    padding: 5px;
    border: 1px solid var(--ProjectColor);
    border-radius: 5px;
    outline: none;
    text-align: center;
}

.ghfytty23 {
    width: 190px !important;
}

.cell_btn12_yy6 {
    border: none;
    color: var(--labelcolor);
    background-color: var(--ProjectColor);
    border-radius: 5px;
    padding: 0px 3px;
}

.ytfdrt659p_head {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.ytfdrt659p {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.jhgfdwtys676 {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: flex-start;
}

.ytfdrt659p label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100px;
    font-size: 12px;
    font-weight: bold;
}

.ytfdrt659p input {

    width: 100px;
    padding: 5px;
    outline: none;
    border: 1px solid var(--ProjectColor);
    border-radius: 5px;
}

.hjsdgcs65 {
    width: 210px !important;
    height: 50px;
    padding: 5px;
    border: none !important;
    box-shadow: none !important;
}

.uyrreftyu7 {
    flex-direction: column;
}

.uyu89 {
    width: 100px;
}

.yytaq2 {
    border: none !important;
    border-bottom: 1px solid var(--ProjectColor) !important
}

.print_buttone99767 {
    display: flex;
    justify-content: center;
    border: none;
    padding: 5px 20px;
    border-radius: 5px;
    background-color: var(--ProjectColor);
    cursor: pointer;

}

.tfttr559 {
    width: 100%;
    display: flex;
    justify-content: center;
}

.utsydtr809 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
}

.dxoip0232d {
    display: flex;
    justify-content: center;
    gap: 5px;

}

.ytrdrte5665yu {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.nmmkyts347 {
    border: 1px solid var(--ProjectColor);
    padding: 5px;
}

.UGDSAP032 {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-bottom: 1px solid var(--ProjectColor);
    padding: 5px;
}

.sre91zqp3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: start;
    row-gap: 5px;
}

.sre91zqp3 h6 {
    margin: 0px;
}

.sre91zqp3 span {
    font-size: 10.5px;
}

.jdusufx6580_table th {
    text-align: center;
    width: 100px;
}

.sde {
    width: 30px !important;
}

.uuyrg56 {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 15px;
}

.ytdtrd {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    font-weight: bold;

}


.ytdtrd label {
    display: flex;
    width: 100px;
    justify-content: space-between;
    align-items: center;
}

.ytdtrd span {
    width: 110px;
}

.sddsxw32121 span {
    width: 200px;
}

.ewdewd8 {
    width: 200px;
    padding: 5px;
    border: none;
    border-bottom: 1px solid var(--ProjectColor);
    outline: none;
    border-radius: 5px;
    text-align: center;
}

.ytewtry99554 {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
}

.fotttteedc6 {
    display: flex;
    justify-content: flex-end;
}

.htydfytfdytd {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

}

.hwedgyed667 th {
    width: 200px !important;
}


/* Style for the table container */
.table_container_refering_Doc_table {
    width: 100%;
    overflow-x: auto;
    /* This will allow horizontal scrolling if needed */
}

/* Style for the table */
.table_container_refering_Doc_table table {
    width: 100%;
    border-collapse: collapse;
}



.table_container_refering_Doc_table th,
.table_container_refering_Doc_table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}


.table_container_refering_Doc_table th.s-no,
.table_container_refering_Doc_table td.s-no {
    width: 10%;
}

.table_container_refering_Doc_table th.billing-date,
.table_container_refering_Doc_table td.billing-date {
    width: 20%;
}

.table_container_refering_Doc_table th.patient-name,
.table_container_refering_Doc_table td.patient-name {
    width: 20%;
}

.table_container_refering_Doc_table th.patient-id,
.table_container_refering_Doc_table td.patient-id {
    width: 20%;
}

.table_container_refering_Doc_table th.test-names,
.table_container_refering_Doc_table td.test-names {
    width: 20%;
    /* Extra width for the "Test Names" column */
}

/* Style for the table headers */
.table_container_refering_Doc_table th {
    background-color: var(--ProjectColor);
    /* Sets a background color for the header cells */
    font-weight: bold;
    /* Makes the text bold */
}

/* Style for the table rows */
.table_container_refering_Doc_table tr:nth-child(even) {
    background-color: #f9f9f9;
    /* Alternates row colors for better readability */
}


.weddsd_p9{
    position: relative;
    left: 500px;
}

.page-numbers {
    font-size: 12px;
    font-weight: bold;
    color: #333;
    text-align: right;
    padding: 3px;
    margin-right: 100px;
  }
  

  .page-numbers-top-ad{
 
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }



  .helpline-container0po {
    display: flex;
    align-items: center;
    border-radius: 30px;
    padding: 2px 15px;
    max-width: 300px;
  
  }
  
  .phone-icon0pfd {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8E201B;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 14px;
    margin-right: 10px;
    border: 1px solid #8E201B;
  }
  
  .helpline-textsxf {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .helpmc3yuj {
    background-color: #174864; 
    color: white;
    padding: 10px 5px ;
    border-radius: 5px 0 0 5px;
    font-weight: bold;
    font-size: 13px;
  }
  
  .number_pus34 {
    background-color: #8E201B; 
    color: white;
    padding: 10px 5px ;
    border-radius: 0 5px 5px 0;
    font-size: 13px;
  }

  .hdyd_p05421{
/* padding: 3px 0px 5px 0px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 370px;

  }

  .hdyd_p05421 img{

    width: 185px;
    height: 80px;
    object-fit: contain;
  }

  .phone-icon0pfd img{
    /* width: 185px; */
    height: 60px;
    object-fit: contain;
  }

  .ehdhe_9ikw{
    width: 100%  !important;
  }

.dcnnmjy0s66{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    /* margin-top: 200px; */
}


  .jjxcdsjjej_{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    width: 100%;

  }




  
.flex_tbdy_tfoot{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.sdncnhd5yu7{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.eedhhdjjd{
    gap: 250px !important;
}

.cjhehc_ecxhj{
    display: flex;
    flex-direction: column;
    width: 100%;
}


.hhdhhd_0{
    font-size: 13px !important;
    align-items: center;
}

.dcndyeu_oikd{
    width: 100%;
    display: flex;
    flex-direction: column;
justify-content: center;
}


.dchfejjj0p div{
display: flex;
width: 100%;
}


.dchfejjj0p span{
    text-align: center;
    font-size: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}



  /* Common styles for all th and td elements */
  .report_table_0op9 th,
  .report_table_0op9 td {
    /* border: 1px solid lightgrey; */
    text-align: left;
    font-size: 12px;
    padding: 5px  !important;
    word-wrap: break-word; /* Ensures long text wraps within the cell */
  }
  
  .report_table_0op9 th{
    padding: 6px 5px !important;
        border: 1px solid lightgrey;

  }



  /* Explicitly set a smaller width for the "Test Name" column */
  .report_table_0op9 th:nth-child(1),
  .report_table_0op9 td:nth-child(1) {
    width: 30%  !important; /* Adjust this value as per your requirement */
  }



  
  /* Define widths for other columns */
  .report_table_0op9 th:nth-child(2),
  .report_table_0op9 td:nth-child(2) {
    width: 20%;
  }

  .report_table_0op9 th:nth-child(2){
    width: 21% !important;
  }
  
  .report_table_0op9 th:nth-child(3),
  .report_table_0op9 td:nth-child(3) {
    width: 15%;
  }
  
  .report_table_0op9 th:nth-child(3){
    width: 15.9% !important;
  }
  .report_table_0op9 th:nth-child(4),
  .report_table_0op9 td:nth-child(4) {
    width: 25%;
  }
  
  .report_table_0op9 th:nth-child(4){
    width: 25% !important;
  }

  .report_table_0op9 th:nth-child(5),
  .report_table_0op9 td:nth-child(5) {
    width: 15%;
  }

  .report_table_0op9 th:nth-child(5){
    width: 15.9% !important;
  }
  
.sdcyysc_uxcds67{
display: flex;width: 100%;
justify-content: center;
gap: 30px;
font-size: 16px  !important;
align-items: center;
}

.dhchche_oici8{
    display: flex;
    gap: 20px;
    align-items: center;
}

.dhchche_oici8 label{
    display: flex;
    gap: 5px;
    align-items: center;
    color: black !important;
}

.sdcyysc_uxcds67 label{
    color: #8E201B;
}

.fhfghf_u_hhhh{
    width: 800px  !important;
}

.hdcnjedd_p{
    font-size: 14px !important;
    align-items: flex-start;
}

.hdcnjedd_p label{
    width: 132px !important;
}

.jdedjjcxj_{
    gap: 5px !important;

}

.jdedjjcxj_ img{
    height: 50px  !important;
}

.jdedjjcxj_ .helpmc3yuj ,.jdedjjcxj_ .number_pus34{
    padding: 4px !important;
}

.jdedjjcxj_ .phone-icon0pfd{
    height: 30px !important;
    width: 30px !important;
}

.jdedjjcxj_ .helpline-container0po{
    padding: 0px  !important;
    margin-right: 50px !important;
}

.yydd_end_reprt{
    font-size: 15px !important;
}

@media print {
    body {
        -webkit-print-color-adjust: exact;
    }

    .Report_master {
        gap: 30px !important;
    }
    

    .print_footerr {
        display: table-footer-group; /* Repeat the footer on each page */
height: 150px !important;
      }
   
      /* .deededsw{
        display: block;
        height: calc(100vh - 100px);
        overflow: auto;
      } */

    .dcnnmjy0s66{
        position: relative;
        right: 30px;
        width: 100%;
    }






  .eedhhdjjd {
    gap: 200px !important;
}




    .head_Container_fix {
        padding: 10px !important;
    }

.dcec_pp {
    display: none;
}

    .print-page3 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* align-items: center; */
        page-break-inside: avoid;
        page-break-after: auto;

        
    }


    .shshxhxs_secfooter{
        position: fixed;
        width: 100%;
        text-align: center;
        bottom: 0;
        left: 1px;
    
    }

    .print-page3jjj{
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* align-items: center; */
        page-break-inside: avoid !important;
    }

  

    .new_billing_div label {
        font-size: 11px !important;
    }

    .flex_head_content {
        display: flex;
        flex-direction: column;
        row-gap: 30px !important;
    }

    .header_print_fix,
    .footer_print_fix {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;

    }





    .header_print_fix {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: transparent;

    }

    .footer_print_fix {
        bottom: 0;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* height:100px !important; */
        background-color: transparent;

    }

    .table_print_fix {
        width: 100%;

        text-align: center;

        border-collapse: collapse;
        /* margin-top: 230px; */

    }

    .table_print_fix th,
    td {
        /* border: 1px solid black; */
        padding: 3px;
        text-align: center;
    }

    .table_print_fix thead {
        display: table-header-group;
    }

    .table_print_fix tbody {
        display: table-row-group;
    }

    .print-buttone {
        display: none;
    }

    .new_billing_address_1_fix {

        display: flex;
        flex-direction: column;
        gap: 5px;
        justify-content: center;
        font-size: 10px;
        align-items: center;
        /* height: 230px; */

    }

    .new_billing_address_2 {
        display: flex;
        flex-direction: column;

        justify-content: flex-start;
        font-size: 12px;
        align-items: center;
        /* height: 230px; */
        /* width: 40%; */
    }

    .footer_fix_content {
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 100px !important;
    }



    .cell_btn12 {
        display: none;
    }

    .cell_btn12_head {
        display: none;
    }

    .tfttr559 {
        display: none;
    }


    .nmmkyts347 {
        width: 100% !important;
    }

    .sde {
        display: none;
    }

    .head_Container_fix {
        padding: 20px !important;
    }

    .New_billlling_invoice_head_fix_space {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 70px;
    }

    /* .new_billing_Report_details{
    width: 100% !important;
} */

    .tytdrdrttdr {
        margin-left: 20px !important;
        padding: 10px !important;
        width: 95% !important;
    }

    .tydy667 p {
        padding-left: 12px !important;
    }

 
.doctr_sign_fix7{
    left: 350px !important;
}


.rtfdfr_kuj{
    position:relative;
    left:300px !important;
    
    
    }   

    .wiioo900{
        gap: 4px !important;
    }

    .weddsd_p9{
        position: relative;
        left: 255px;
        width: 100% !important;
        color: grey;
    }

    .weddsd_p9 span{
        width: 200px !important;
    }

    .page-numbers-top-ad{
 position:relative;
 box-sizing: border-box;
 /* margin-top: 0; */
 left: 50px !important;
 top: 40px !important;
 padding: 5px 0px;
 margin: 5px 0px;
 /* transform: translateY(-150px); */

        width: 100%;
        display: flex;
        justify-content: flex-end;
      }

      /* .jjjdk_ii{
        transform: translateY(20px);
      } */

      .jjxcdsjjej_{
        width: 105% !important;
      }
    

/* Header styling */
.printheader_header {
    position: fixed;
    top: 0;
    height: 100px;
    width: 100% !important;
    z-index: 1000;
    margin-top: 5px;
    margin-bottom: 30px !important;
    padding-bottom: 10px; /* Add padding to create space below header */
  }
  
  /* Body styling */
  .body_plo {
    margin-top: 50px; /* Ensure the body content starts below the fixed header */
  }
  
  /* Footer styling */
  .Print_footer3456 {
    position: fixed;
    bottom: 0;
    z-index: 1000;
  }
  
  /* Ensuring each page break has header and footer */
  .fhfghf_u_hhhh {
    page-break-after: always;
  }
  

  /* .print-table33 {
    width: 85% !important;
    height: 100vh;
    position: relative;
  left: 10px;
} */

}


@media print {
    .table_container_refering_Doc_table {
        width: 100%;
    }

    .table_container_refering_Doc_table table {
        width: 100%;
    }

    .table_container_refering_Doc_table th.s-no,
    .table_container_refering_Doc_table td.s-no,
    .table_container_refering_Doc_table th.billing-date,
    .table_container_refering_Doc_table td.billing-date,
    .table_container_refering_Doc_table th.patient-name,
    .table_container_refering_Doc_table td.patient-name,
    .table_container_refering_Doc_table th.patient-id,
    .table_container_refering_Doc_table td.patient-id,
    .table_container_refering_Doc_table th.test-names,
    .table_container_refering_Doc_table td.test-names {
        width: 20%;
        /* Reset to automatic width for consistent printing */
    }

    .table_container_refering_Doc_table th.test-names,
    .table_container_refering_Doc_table td.test-names {
        width: 420px;
        /* Reset to automatic width for consistent printing */
    }


    .table_container_refering_Doc_table th,
    .table_container_refering_Doc_table td {
        padding: 8px;
        /* Ensure padding for print */
        text-align: center;
        /* Align text to the left */
    }


    .trrfrt5787 {
        margin-left: -10px;
    }



    /* body {
        counter-reset: page;
    }
    .pageNumber::before {
        counter-increment: page;
        content: counter(page);
    } */


}


.pageNumber {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  }
  


