.total_calendar{
    padding: 10px;
    width: 98%;
}

.total-emp-cald-con{
  width: 97%;
 
  height: 100%;
  /* overflow-y:auto; */
/* margin-left: 13px; */
/* padding: 10px; */

}


/* Hide scrollbar for Chrome, Safari, and newer Edge */
.total-emp-cald-con::-webkit-scrollbar {
  width: 0.5em;
}

.total-emp-cald-con::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/* Hide scrollbar for Firefox */
.total-emp-cald-con {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

/* Hide scrollbar for Microsoft Edge (old version) */
.total-emp-cald-con {
  -ms-overflow-style: none;
}
.total-emp-cald-con::-ms-scrollbar-thumb {
  background-color: transparent;
}

.emplee-calendar-contnr{
  height: 100vh;
  overflow-y: auto;
}
.employee-calender-title{
  width: 100%;
  padding: 15px;
}
.h_head-emp-cldr{
  background-color:var(--ProjectColor);
  padding: 10px;
  display: flex;
  border-radius: 5px;
  justify-content: flex-start;
}

.calendar_head{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    background-color:var(--ProjectColor);
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 0px 20px;
    box-sizing: border-box;
  
}
.calender_select_colr{
  color: var(--projectwhite);
}
.calender_select_colr option{
  color: var(--labelcolor);
}

.calender_select_opt{
    font-weight: 600;
    font-size: 15px;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    text-align: center;

    /* color: white; */
}
.calender_select_opt select{
    border: 1px solid white;
    outline: 0px;
    width: 80px;
    background-color:var(--ProjectColor);

}

.slect-view-blk label{
  color: var(--labelcolor);
}


@media screen and (max-width:768px){
  .calendar_head{
    width: 99%;
    font-size: 15px;
  }
  .calender_select_opt {
    font-size: 13px;
  }
}

/* monthly css.............. */


body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f0f0f0;
  }
  
  /* Calendar container */
  
  .calendar-container {
    height: 90%;
    width: 95%;
    margin: 0 auto;
    padding: 0px;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Calendar styles */
  
  .calendar {
    width: 98%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 0px 10px;
    margin: 10px;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    
  }
  .calender_table_overall{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    /* margin-bottom: 10px; */
  
    box-sizing: border-box;
  }
  
  
  .calender_table {
    width: 100%;
    overflow: scroll;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .calender_table::-webkit-scrollbar {
    display: none;
  }
  
  .calender_table_overall table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .calender_table_overall thead {
    background-color:var(--ProjectColor);
    color: white;
  }
  
  .calender_table_overall th {
    padding: 10px;
    text-align: center;
    border: 1px solid var(--ProjectColor);
  }
  
  .calender_table_overall td {
    border: 1px solid var(--ProjectColor);
    padding: 10px;
    width: 80px;
    height: 80px;
    text-align: center;
  }
  
  .calender_app {
    display: grid;
    place-items: center;
    padding-bottom: 5px;
  }
  
  .select_items_appointment {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin: 20px;
    align-items: center;
  }
  .gap-fr-emplcldr{
    display: flex;
    justify-content: center;
    gap: 80px;
  }
  
  .doctor_select {
    color:var(--labelcolor);
    display: flex;
    /* gap: 40px; */
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    align-items: center;
   
  }

  .fnt-ic-book{
    font-size: 15px !important;
    font-weight: bold;
  }

  .crt-the-emlclnd-wth{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    row-gap: 10px;
    align-items: center;
 
    /* border-bottom: 1px  solid var(--ProjectColor); */
  }
  .crt-the-emlclnd-wth label, .crt-the-emlclnd-wth span{
    /* border: none; */
    border-radius: 5px;
    /* font-size: 15px; */
    border-color: black;

    /* padding: 5px; */
  
  }
  .crt-the-emlclnd-wth input{
        /* border: none; */
        border-radius: 5px;
        font-size: 14px;
        border: 1px solid var(--ProjectColor);
outline: none;
        height: 12px;
        padding: 5px;
  }
  .crt-the-emlclnd-wth span{
    font-size: 15px;
  }
  .crt-the-emlclnd-wth label{
    width: 130px;
    background-color:var(--ProjectColor);
    color:var(--labelcolor);
    font-weight: bold;
    font-size: 15px;
    padding: 4px 10px;
  }
  .lve-cdd-r label{
    background-color: transparent;
    width: 110px !important;
    font-size: 15px;
    font-weight: bold;
   color:var(--labelcolor);
  }
  .doctor_select select {
    border: 0px;
    outline: 0px;
    width: 100px;
    background-color: var(--selectbackgroundcolor);
  }
  .doctor_select select{
    height: auto;
    width: 150px;
    background-color: var(--selectbackgroundcolor);
  }
  .appoint_booked_count {
    color:var(--labelcolor);
    font-size: 13px;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .appoint_booked_count label{
    font-weight: bold;
    width: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .appoint_booked_count span {
    color: var(--labelcolor);
  }
  
  .day {
    width: 100%;
    height: 100%;
    padding: 5px 0px;
  }
  
  .date {
    font-weight: bold;
    font-size: 1.2rem;
  }
  
  .appointment_cal {
    height: 20px;
    padding: 5px;
    box-sizing: border-box;
  }
  
  .appointment_cal h3 {
    margin: 0px;
    font-size: 12px;
    width: max-content;
    text-align: center;
    color:var(--labelcolor);
  }
  
  .appointment_body_1 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    margin-top: 10px;
    font-size: var(--fontsize);
  }
  
  .appointment_data_1 div {
    width: 45%;
    color: gray;
    display: flex;
    justify-content: space-evenly;
  }
  
  .appointment_data_1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    gap: 10px;
  }
  
  .appointment_count_1 {
    color:var(--labelcolor);
    font-weight: 600;
  }
  
  .appointment_data_1 button{
    border: 0px;
    outline: 0px;
    background-color: transparent;
    height: 100%;
    width: 20px;
    display: grid;
    place-items: center;
    position: absolute;
    cursor: pointer;
    right: 0px;
    /* top: 5px; */
  }

  .DFC_BV{
    gap: 20px;
  }
  .booked_icon{
    font-size: 15px !important;
    color:var(--labelcolor);
  }
  .cal_mon_1{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    color:var(--labelcolor);
    padding-top: 10px;
  }
  
  .day:hover{
    cursor: pointer;
    background-color: var(--ProjectColorhover);
  }
  .cal_mon_1 h3{
    width: 170px !important;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cal_mon_1 button{
    border: 0px;
    outline: 0px;
    color:var(--labelcolor);
    background-color: transparent;
    cursor: pointer;
  }
  .cal_mon_1 button:active{
    border-radius: 25%;
    background-color: var(--ProjectColorhover);
    color: var(--projectwhite);
  }


  .sdjkfhdf_7g {
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    gap: 25px;
  }
  
  
  
  .rdgdr_8jyu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    font-size: 13px;
    gap: 10px;
  }


.jdfy_53d label,.rdgdr_8jyu label{
  display: flex;
  width: 105px;
  font-weight: bold;
  justify-content: space-between;

}

.jdfy_53d p{
  width: 100px;
}

.jshudu_42{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.drnhkisfdhl{
display: flex;
  border-radius: 10px;
  gap: 20px;
  align-items: center;
  /* background-color: var(--ProjectColor); */
}
.drnhkisfdhl button{
  width: 35px;
  padding: 0px 4px;
padding: 2px 4px;
border-radius: 5px;
background-color: var(--ProjectColor);
cursor: pointer;
}

  /* Responsive styles */
  
  @media ( max-width: 1130px ) {
    .doctor_select select {
      background-color:var(--selectbackgroundcolor);
      width: fit-content;
      width: 150px;
    }
   
    .total_calendar{
      width: 98%;
    }
    .calendar-container {
      width: 100%;
      /* overflow: auto; */
      padding: 10px 0px;
    }
  
    /* .calender_table {
      width: 80%;
      overflow-x: scroll;
    } */
  
    .calendar {
      width: 95%;
      font-size: 12px;
    }
  
    .day {
      width: 150px;
      height: 100px;
    }
    .gap-fr-emplcldr{
      display: flex;
      justify-content: center;
      gap: 25px;
    }
    .employee-calender-title{
      width: 98%;
      padding: 15px;
    }
    .calendar_head{
      width: 99%;
      font-size: 15px;
    }



  }
  
  /* Modal styles */
  
  .modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .App_Cal_modal {
    background-color: #fff;
    width: 300px;
    height: 300px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    position: relative;
    padding-top: 18px;
  }
  
  .App_Cal_modal h3 {
    margin: 0;
    color:var(--labelcolor);
    font-size: 16px;
  }
  
  .App_Cal_modal ul {
    padding: 0;
    width: 70%;
    height: 200px;
    list-style: none;
    margin-top: 10px;
    overflow-y: auto;
    color: rgb(175, 172, 172);
    /* display: grid;
    grid-template-columns: repeat(auto-fit,minmax(100px,1fr)); */
    background-color: rgb(248, 247, 247);
  box-shadow: 1px 1px 5px 5px rgb(231, 229, 229);
  }
  
  .App_Cal_modal li {
    margin-top: 5px;
    font-size: 14px;
  }
  
.booked_app_btn {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 40px;
    width: 40px;
    background-color:transparent;
    color:var(--labelcolor);
    border: none;
    cursor: pointer;
    border-radius: 50%;
  }
  .booked_app_1_btn {
    width: 70%;
    display: flex;
    justify-content: space-around;
  }
  .booked_app_1_btn button{
    
    width: auto;
    background-color: var(--ProjectColor);
    color: var(--labelcolor);
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    border-radius: 15px;
  }
  .App_Cal_modal ul::-webkit-scrollbar{
    width: 2px;
    background-color: var(--ProjectColor);
    
  }

  @media(max-width:768px){
    .App_Cal_modal{
      width: 250px;
      height: 300px;
    }
    .employee-calender-title{
      width: 95%;
      padding: 15px;
    }

    
    .total_calendar{
      width: 96%;
    }
  }
  
  
  /* weekly css.... */

  /* Default styles for the calendar */
.calendar_weekly .body .selected {
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(45deg, #1a8fff 0%, #70ceea 10%);
  border-image-slice: 1;
}

.calendar_weekly .body .today {
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(45deg, #ff1a79 0%, #eb82b3 40%);
  border-image-slice: 1;
}

.calendar_weekly {
  width: 100%; /* Adjust the max-width as needed */
  /* height: 100% !important; */
  margin: 0 auto;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  border-radius: 5px;
  padding: 10px;
}
.new_week_footer{
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: var(--ProjectColor);
}
.Week_header{
    width: 100%;
    display: flex;
    align-items: center;
    height: 40px;
    background-color:var(--ProjectColor);
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 0px 10px;
    box-sizing: border-box;
}

.days_date{
  width: 100%;
  overflow:auto;
  margin: 20px;
  border: 1px  solid var(--ProjectColor);
}
.days {
  display: flex;
  background-color:var(--ProjectColor);
  height: 40px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-collapse: collapse;
  padding: 4px;
  overflow-x: auto; /* Add horizontal scroll for small screens */
}
.row{
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
  height: 150px;
  text-align: center;
  border-right: 1px  solid var(--ProjectColor);
  
}
.col {
  flex: 1;
  display:flex;
  align-items: center;
  justify-content: center;
  height: 80% ;
  padding: 0px;
  color: white;
  border-collapse: collapse;
  /* border-right: 1px solid #eeeded; */
  white-space: nowrap; /* Prevent dates from wrapping */
  box-sizing: border-box; /* Include padding and borders in width calculation */
  display: flex; /* Display dates in columns */
  flex-direction: column; /* Arrange dates vertically within each column */
}

.appointment_details_week{
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.app_d_week_1{
  height: 40px;
  display: flex;
  align-items: center;
  width: 350px;

  justify-content: flex-start;
  color:var(--labelcolor);
  
}

.app_d_week_1 h4{
  display: flex;

  gap: 10px;
  justify-content: space-between;
  align-items: center;
  text-align:start;
  width: 210px;
  font-size: 14px;
}
.app_d_week_1 p{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  margin-left: 5px;
  height: 100%;
  /* width: 110px; */
  color: gray;
  margin-left: 5px;
}
.app_week_det_1{
  margin-top: 20px;
  padding: 0px 5px;
  padding: 5px;
  width: 70%;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.app_week_det_2{
  margin-top: 10px;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px  solid var(--ProjectColor);

}


/* Responsive styles for small screens */
@media (max-width: 768px) {
  .app_d_week_1{
    font-size: 12px;
  }
  .app_week_det_1{
    width: 100%;
  }
  
  .calendar_weekly {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 100%; /* Take full width on small screens */
    margin: 0;
  }

  .days {
    height: 30px;
    width: 810px;
    /* padding: 10px; */
    
    flex-direction: row; /* Stack days vertically on small screens */
    overflow-x: hidden; /* Hide horizontal scroll on small screens */
  }

  .col {
    border: none; /* Remove borders for small screens */
    min-width: auto; /* Reset minimum width */
    flex: 1;
    text-align: center;
    padding: 0px;
    border-right: 1px solid #eeeded;
    white-space: nowrap; /* Prevent dates from wrapping */
    box-sizing: border-box; /* Include padding and borders in width calculation */
    display: flex; /* Display dates in columns */
    flex-direction: column; /* Arrange dates vertically within each column */
  }

  .days_date{
    width: 100%;
  }
 
  .calendar {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 0px 10px;
    margin: 20px;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }

  .doctor_select select {
    width: fit-content;
    width: 150px;
    background-color: var(--selectbackgroundcolor);
  }
  .row{
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* flex-direction: row; */
  /* box-sizing: border-box; */
  width: 812px;
  /* height: 150px; */
  /* text-align: center; */
  /* border-right: 1px  solid var(--ProjectColor); */
  
}
.gap-fr-emplcldr{
  display: flex;
  justify-content: center;
  gap: 25px;
}
}

@media (max-width: 520px) {

  .total_calendar{
    width: 94%;
    padding: 5px;
  }

 
  .select_items_appointment{
    gap: 10px;
  }

  .jhsdfhk{
    display: flex;
    flex-direction: column;
  }

  .app_d_week_1{
    font-size: 13px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 215px !important;
  }
  .app_week_det_2{
    justify-content: space-between;
    width: 95%;
  }
  .calendar_weekly {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px 0px;
    max-width: 100%; /* Take full width on small screens */
    margin: 0;
  }

  .calendar {
    width: 88%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 0px 10px;
    margin: 20px;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }

.crt-the-emlclnd-wth{
  display: flex;
  flex-wrap: wrap;
  width: 230px;
  row-gap: 0px;
  gap: 5px;
  justify-content: center;
  align-items: center;
 text-align: center;
}
.crt-the-emlclnd-wth label{
  display: flex;
  width: 175px;
  font-size: 12px;
  padding: 5px;
  justify-content: center;
}


  .doctor_select select {
    width: fit-content;
    width: 118px;
    font-size: 13px;
    background-color: var(--selectbackgroundcolor);
  }

  .days {
    height: 30px;
    flex-direction: row; /* Stack days vertically on small screens */
    overflow-x: hidden; /* Hide horizontal scroll on small screens */
   
  }
  /* .row{
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    box-sizing: border-box;
    width: 100%;
    height: 150px;
    text-align: center;
    border-right: 1px  solid var(--ProjectColor);
    
  } */
  .col {
    height: 15px;
    border: none; /* Remove borders for small screens */
    min-width: auto; /* Reset minimum width */
    flex: 1;
    text-align: center;
    color: var(--labelcolor);
    padding: 0px;
    border-right: 1px solid #eeeded;
    white-space: nowrap; /* Prevent dates from wrapping */
    box-sizing: border-box; /* Include padding and borders in width calculation */
    display: flex; /* Display dates in columns */
    flex-direction: column; /* Arrange dates vertically within each column */
  }

  .fnt-ic-book{
    width: 125px;
    flex-direction: column;
    text-align: center;
    display: flex;
    justify-content: center
  }
  .gap-fr-emplcldr{
    display: flex;
    justify-content: center;
    gap: 25px;
  }

  .employee-calender-title{
    width: 90%;
    padding: 15px;
  }

  .crt-the-emlclnd-wth input{
    /* border: none; */
    border-radius: 5px;
    font-size: 14px;

height: 10px;
    padding: 6px 5px;
    width: 170px;
}
  
.select_items_appointment {
  width: 216px;
}

.app_d_week_1 h4{
  width: 110px;
}

}

/* day view..... */



/* Summa1.css */

/* Timeline container */
.timeline {
  display: flex;
  flex-direction: column;
  border-left: 1px solid #ccc;
  padding-left: 20px;
}

/* Timeline item (hour) */
.timeline-item {
  height: 50px;
  display: flex;
  padding-left: 10px;
  border-bottom: 1px  solid var(--ProjectColor);

}

/* Time slot */
.time {
  width: 110px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  font-weight: bold;
}


/* Appointment item */

.appointment_det_name {
  background-color:var(--ProjectColor);
  color: var(--labelcolor);
  padding: 5px;
  border-radius: 5px;
}

.app_d_week2{
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color:var(--labelcolor);
}

.app_d_week2 p{
  display: flex;
  align-items: center;
  margin-left: 5px;
  height: 100%;
  color: gray;
}

.timeline{
  margin-top: 20px;
  width: 60%;
  padding: 0px 10px;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  /* flex-direction: column; */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.new_day_app{
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}
.appointment_content{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
}
.calender-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media screen and (max-width:920px) {
  .new_day_app{
    display: flex;
   flex-direction: column;
   gap: 5px;
  }
  .timeline-item {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
    padding-left: 10px;
    border-bottom: 1px  solid var(--ProjectColor);
 
  }
  .timeline{
    width: 80%;
  }
  .app_d_week2{
    font-size:12px;
 }
}


.gnfghhfgh{
  display: flex;
  justify-content: space-evenly !important;
gap: 50px !important;
}

.new__jhtf55{
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.new__jhtf55 span{
  display: flex;
/* flex-direction: column; */
flex-wrap: wrap;
font-size: 11px;
width: 200px;
}


.drefgrgree{
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;
  row-gap: 5px;
  /* gap: 75px */
}

.drefgrgree2{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.drefgrgree33{
  display: flex;
width: 100%;
gap: 10px;
justify-content: flex-end;
  font-size: 11px;
  padding: 1px;
}
.drefgrgree33 label{
  width: 100px;
  display: flex;
  justify-content: space-between;
}

.drefgrgree33 h4{
width: 150px;
}

.drefgrgree_head{
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.kkk_ghfg {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.kkk_ghfg th ,.kkk_ghfg td{
  font-size: 11px;
  padding: 2px;
}

.kkk_ghfg td,.kkk_ghfg th{
  text-align: start;
}


.hjyttyty_{
  margin-top: 0px !important;
}

.jsegfgseyu{
  margin-top: 5px !important;
}

.rgdrg{
  justify-content: flex-start !important;
  margin-left: 20px;
}

.nhbyh_5443f7{
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  padding: 3px;
  width: 100%;
}

.nhbyh_5443f7  label{
  font-weight: bold;
  width: 116px;
  display: flex;
  justify-content:space-between;
}

.nhbyh_5443f7 h3{
  width: 200px;
  text-align: start;
  font-size: 12px;
}


.luutt_8 {
  width: 100%;
  display: flex;align-items: center;
  font-size: 13px;
  gap: 5px;
  margin-left: 6px;


}
.luutt_8 label{
width: 125px;
font-weight: bold;display: flex;
justify-content: space-between;
}

.luutt_8 h3{
  font-size: 12px;
}

.total_con_bill_jj{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* flex-direction: column; */
}

.fdfgdfgd {
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start !important;
}

.drefgrgree33_bar{
  justify-content: center !important;
}

.drefgrgree_9loyuhky{
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
}


